import React, { Component } from 'react';
import { connect } from 'react-redux';
import Template from '@bit/dinomedia.objective1-campaign.objective1-campaign-event';
import { Base64 } from 'js-base64';
import SliderElmComponent from '../../components/chrome/slider'; 

import { previewGet } from '../../actions/hub';

class Preview extends Component {

	constructor(props){
		super(props);
		
		console.log('PREVIEW')
		
		let { token } = this.props.match.params;
				
		if(!token){
			this.props.history.push('/');
		}
		
		this.props.previewGet(token, () => {
			this.props.history.push('/');
		});
	}
	
	render() {
				
		if(!this.props.hub.preview){
			return '';
		}
		
		let style = {
			display: 'inline-block'
		}
				
		switch(this.props.hub.preview.type){
			
			case 'advert':
			case 'slider':
				style.width = '1000px';
				style.height = '400px';
				break;
				
			case 'facebook':
			case 'twitter':
				style.padding = '20px';
				break;
		}
						
		return (
			<span id="preview" style={style}>
				<Template 
					template={this.props.hub.preview.template}
					branding={this.props.hub.preview.brand}
					data={this.props.hub.preview.data}
				/>
			</span>
		);
	}
}

function mapStateToProps({ hub, brand }, ownProps){
	return {
		hub,
		brand
	};
}

export default connect(mapStateToProps, { 
	previewGet
})(Preview);