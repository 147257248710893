!function (e, t) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = t(require("html-to-react"), require("react"), require("react-medium-editor"), require("react-slick"), require("lodash"), require("react-player"), require("sweetalert2"), require("sweetalert2-react-content"), require("moment-timezone"), require("moment/min/locales"), require("text-clipper"), require("react-nl2br"));else if ("function" == typeof define && define.amd) define(["html-to-react", "react", "react-medium-editor", "react-slick", "lodash", "react-player", "sweetalert2", "sweetalert2-react-content", "moment-timezone", "moment/min/locales", "text-clipper", "react-nl2br"], t);else {
    var r = "object" == typeof exports ? t(require("html-to-react"), require("react"), require("react-medium-editor"), require("react-slick"), require("lodash"), require("react-player"), require("sweetalert2"), require("sweetalert2-react-content"), require("moment-timezone"), require("moment/min/locales"), require("text-clipper"), require("react-nl2br")) : t(e["html-to-react"], e.react, e["react-medium-editor"], e["react-slick"], e.lodash, e["react-player"], e.sweetalert2, e["sweetalert2-react-content"], e["moment-timezone"], e["moment/min/locales"], e["text-clipper"], e["react-nl2br"]);

    for (var a in r) {
      ("object" == typeof exports ? exports : e)[a] = r[a];
    }
  }
}(window, function (e, t, r, a, n, s, i, l, o, c, u, d) {
  return function (e) {
    var t = {};

    function r(a) {
      if (t[a]) return t[a].exports;
      var n = t[a] = {
        i: a,
        l: !1,
        exports: {}
      };
      return e[a].call(n.exports, n, n.exports, r), n.l = !0, n.exports;
    }

    return r.m = e, r.c = t, r.d = function (e, t, a) {
      r.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: a
      });
    }, r.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, r.t = function (e, t) {
      if (1 & t && (e = r(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var a = Object.create(null);
      if (r.r(a), Object.defineProperty(a, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var n in e) {
        r.d(a, n, function (t) {
          return e[t];
        }.bind(null, n));
      }
      return a;
    }, r.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return r.d(t, "a", t), t;
    }, r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, r.p = "", r(r.s = 0);
  }([function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });

    var a = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var r = arguments[t];

        for (var a in r) {
          Object.prototype.hasOwnProperty.call(r, a) && (e[a] = r[a]);
        }
      }

      return e;
    },
        n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    },
        s = function s(e, t) {
      if (Array.isArray(e)) return e;
      if (Symbol.iterator in Object(e)) return function (e, t) {
        var r = [],
            a = !0,
            n = !1,
            s = void 0;

        try {
          for (var i, l = e[Symbol.iterator](); !(a = (i = l.next()).done) && (r.push(i.value), !t || r.length !== t); a = !0) {
            ;
          }
        } catch (e) {
          n = !0, s = e;
        } finally {
          try {
            !a && l.return && l.return();
          } finally {
            if (n) throw s;
          }
        }

        return r;
      }(e, t);
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    },
        i = function () {
      function e(e, t) {
        for (var r = 0; r < t.length; r++) {
          var a = t[r];
          a.enumerable = a.enumerable || !1, a.configurable = !0, "value" in a && (a.writable = !0), Object.defineProperty(e, a.key, a);
        }
      }

      return function (t, r, a) {
        return r && e(t.prototype, r), a && e(t, a), t;
      };
    }(),
        l = r(2),
        o = y(l),
        c = r(3),
        u = y(r(4)),
        d = y(r(5)),
        p = y(r(6)),
        f = y(r(7)),
        m = y(r(8)),
        h = y(r(9)),
        g = y(r(10));

    r(11);
    var v = y(r(0)),
        b = y(r(12));

    function y(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }

    var E = r(13),
        w = r(1),
        k = r(1).Parser,
        x = (0, h.default)(m.default),
        N = function (e) {
      function t(e) {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, t);

        var r = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this, e));

        return r.state = {
          maxed: !1,
          hover: []
        }, r.slider = [], r;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(t, e), i(t, [{
        key: "setHover",
        value: function value(e, t) {
          var r = this.state.hover;
          r[e] = t, this.setState({
            hover: r
          });
        }
      }, {
        key: "setContent",
        value: function value(e, t, r, a) {
          a.value ? a = a.value : a.target && (a = a.target.value);
          var n = this.props.data.sections;
          n[e].data[t][r] = a, this.props.edit.onChange("sections", n);
        }
      }, {
        key: "setConfig",
        value: function value(e, t, r) {
          var a = this.props.data.sections;
          a[e].config || (a[e].config = {}), a[e].config[t] = r, this.props.edit.onChange("sections", a);
        }
      }, {
        key: "renderBranding",
        value: function value(e) {
          return Object.entries(this.props.branding).forEach(function (t) {
            var r = s(t, 2),
                a = r[0],
                i = r[1];
            if ("object" === (void 0 === i ? "undefined" : n(i))) Object.entries(i).forEach(function (t) {
              var r = s(t, 2),
                  n = r[0],
                  i = r[1];

              if (i) {
                switch (n) {
                  case "logo":
                  case "icon":
                    "data:image" !== i.substring(0, 10) && (i = (0, c.imageResize)(i, 500));
                }

                e = e.replace(new RegExp("{{" + a + "." + n + "}}", "g"), i);
              } else e = e.replace(new RegExp("{{" + a + "." + n + "}}", "g"), "");
            });else if (i) {
              switch (a) {
                case "logo":
                case "icon":
                  "data:image" !== i.substring(0, 10) && (i = (0, c.imageResize)(i, 500));
              }

              e = e.replace(new RegExp("{{" + a + "}}", "g"), i);
            } else e = e.replace(new RegExp("{{" + a + "}}", "g"), "");
          }), e;
        }
      }, {
        key: "renderCSS",
        value: function value(e) {
          return e = (e = e.replace(new RegExp("{{font_heading}}", "g"), this.props.branding.font_heading.family)).replace(new RegExp("{{font_text}}", "g"), this.props.branding.font_text.family);
        }
      }, {
        key: "renderStatic",
        value: function value(e, t) {
          var r = this;
          t && p.default.forEach(t, function (t, a) {
            switch (a) {
              case "url":
                "" !== t && null !== t || (t = r.props.branding.url_website ? r.props.branding.url_website : r.props.branding.url_hub);
            }

            e = e.replace(new RegExp("{{" + a + "}}", "g"), t);
          });
          var a = this.props.branding.url_hub;
          this.props.data.cta && (a = this.props.data.cta), e = e.replace(new RegExp("{{url_cta}}", "g"), a), t = this.props.data;
          var n = JSON.parse(this.props.template.settings);
          return p.default.forEach(n, function (r, a) {
            e = e.replace(new RegExp("{{" + a + "}}", "g"), t[a]);
          }), e;
        }
      }, {
        key: "renderElement",
        value: function value(e, t, r, s, i, l, m) {
          var h = this,
              y = function y(e) {
            var t = e.style,
                r = e.onClick;
            return o.default.createElement("i", {
              className: "arrow next fas fa-angle-right click-allowed",
              style: a({}, t),
              onClick: r
            });
          },
              w = function w(e) {
            var t = e.style,
                r = e.onClick;
            return o.default.createElement("i", {
              className: "arrow previous fas fa-angle-left click-allowed",
              style: a({}, t),
              onClick: r
            });
          },
              x = function x(e, t) {
            return (0, b.default)(e, t, {
              html: !0
            });
          },
              N = [],
              _ = "",
              S = "no-margins",
              C = [];

          s && p.default.forEach(s.attribs, function (e, t) {
            switch (t) {
              case "data-element":
                break;

              case "class":
                S = S + " " + e;
                break;

              default:
                C[t] = e;
            }
          });
          var j = !1;

          if ((m = m[t]).source_sub && !r && (r = this.props.data.sections[i].data[l][t], m.source = m.source_sub), r) {
            switch (j = r[m.source], m.type) {
              case "image":
                var O = (0, c.imageResize)(j, m.width ? m.width : null, m.height ? m.height : null, !!m.fit && m.fit);
                if (m.background) _ = o.default.createElement("div", {
                  className: "image",
                  style: {
                    backgroundImage: "url(" + O + ")"
                  }
                });else {
                  C.style && delete C.style;
                  var P = "";
                  C.alt && (P = C.alt, delete C.alt), _ = o.default.createElement("img", a({
                    src: O,
                    className: "" + S,
                    alt: P
                  }, C));
                }
                r.imagePlaceholder && (_ = o.default.createElement("div", {
                  className: "placeholder_container"
                }, _, o.default.createElement("div", {
                  className: "placeholder_label"
                }, r.imagePlaceholder)));
                break;

              case "url":
                _ = j;
                break;

              case "text":
                m.max && (j = x(j, m.max)), j = j.replace(/(?:\r\n|\r|\n)/g, "<br/>"), _ = this.renderHtml(j);
                break;

              case "date":
                var q = "LLL";
                m.format && (q = m.format), j && j.date && (j = j.date), _ = this.renderHtml(g.default.utc(j).local().format(q));
            }

            N.push(_);
          } else {
            switch (m.type) {
              case "logo":
                j = !0;
                break;

              case "links":
                this.props.branding.url_footer ? "string" == typeof (j = this.props.branding.url_footer) && (j = JSON.parse(j)) : j = !1;
                break;

              case "template":
                this.props.data.sections[i].data[l][t].data && (j = this.props.view || "slider" !== this.props.data.sections[i].data[l][t].template.type ? o.default.createElement(v.default, {
                  template: this.props.data.sections[i].data[l][t].template,
                  branding: this.props.branding,
                  data: this.props.data.sections[i].data[l][t].data,
                  child: !0
                }) : o.default.createElement("iframe", {
                  title: "Slider",
                  src: "/slider"
                }));
                break;

              default:
                j = this.props.data.sections[i].data[l][t];
            }

            if ("display" === e) return !!j;
            var H = !1,
                R = 0;

            switch (m.type) {
              case "template":
                N.push(j);
                break;

              case "logo":
                if (this.props.branding.logo) {
                  C.style && delete C.style;
                  var I = this.props.branding.logo;
                  "data:image" !== I.substring(0, 10) && (I = (0, c.imageResize)(I, m.width, m.height));
                  var M = "";
                  C.alt && (M = C.alt, delete C.alt), _ = o.default.createElement("img", a({
                    src: I,
                    alt: M
                  }, C));
                } else _ = o.default.createElement("div", null, this.props.branding.name);

                N.push(_);
                break;

              case "links":
                if (j && "object" === (void 0 === j ? "undefined" : n(j))) {
                  _ = [];
                  var T = this.props.template.blocks[m.block];
                  j.forEach(function (e, t) {
                    var r = T.html;
                    r = (r = r.replace(new RegExp("{{url}}", "g"), e.url)).replace(new RegExp("{{label}}", "g"), e.label);
                    var a = new k();

                    _.push(a.parse(r));
                  }), N.push(_);
                }

                break;

              case "image":
                var z = [];

                if (H = !1, R = 0, this.props.edit ? this.props.items[m.source].forEach(function (e, t) {
                  z.push({
                    id: !!e.id && e.id,
                    src: (0, c.imageResize)(e.image, m.width, m.height)
                  }), j && j === e.id && (H = R), ++R;
                }) : (z = [{
                  src: (0, c.imageResize)(j.image, m.width, m.height),
                  placeholder: j.imagePlaceholder
                }], H = 0), this.props.edit && this.props.edit.enabled) {
                  var B = i + "_" + t;
                  this.slider[B] = [], _ = o.default.createElement("div", {
                    className: "slider-container " + (m.background ? "slider-resizer" : ""),
                    onMouseEnter: function onMouseEnter() {
                      h.setHover(B, !0);
                    },
                    onMouseLeave: function onMouseLeave() {
                      h.setHover(B, !1);
                    }
                  }, o.default.createElement(d.default, {
                    ref: function ref(e) {
                      return h.slider[B] = e;
                    },
                    slidesToShow: "1",
                    slidesToScroll: "1",
                    fade: !0,
                    infinite: !0,
                    initialSlide: H,
                    adaptiveHeight: !0,
                    className: S + " " + (this.state.hover[B] ? "hover" : ""),
                    beforeChange: function beforeChange(e, r) {
                      setTimeout(function () {
                        h.setContent(i, l, t, z[r].id);
                      }, 250);
                    },
                    nextArrow: o.default.createElement(y, null),
                    prevArrow: o.default.createElement(w, null)
                  }, z.map(function (e, t) {
                    return m.background ? o.default.createElement("div", {
                      key: B + "_" + t
                    }, o.default.createElement("div", {
                      className: "image",
                      style: {
                        backgroundImage: "url(" + e.src + ")"
                      }
                    })) : o.default.createElement("div", {
                      key: B + "_" + t
                    }, o.default.createElement("img", {
                      src: e.src,
                      alt: ""
                    }));
                  })));
                } else if (!1 !== H) {
                  if (m.background) _ = o.default.createElement("div", {
                    className: "image",
                    style: {
                      backgroundImage: "url(" + z[H].src + ")"
                    }
                  });else {
                    C.style && delete C.style;
                    var L = "";
                    C.alt && (L = C.alt, delete C.alt), _ = o.default.createElement("img", a({
                      src: z[H].src,
                      alt: L,
                      className: "" + S
                    }, C));
                  }
                  z[H].placeholder && (_ = o.default.createElement("div", {
                    className: "placeholder_container"
                  }, _, o.default.createElement("div", {
                    className: "placeholder_label"
                  }, z[H].placeholder)));
                }

                N.push(_);
                break;

              case "video":
                var W = [];

                if (H = !1, R = 0, this.props.edit ? this.props.items[m.source].forEach(function (e, t) {
                  W.push({
                    id: !!e.id && e.id,
                    thumbnail: (0, c.imageResize)(e.thumbnail, m.width, m.height, !1, !m.embed && "play"),
                    url: e.url,
                    page: e.page
                  }), j && j === e.id && (H = R), ++R;
                }) : (W = [{
                  thumbnail: (0, c.imageResize)(j.thumbnail, m.width, m.height, !1, !m.embed && "play"),
                  url: j.url,
                  placeholder: j.imagePlaceholder,
                  page: j.page
                }], H = 0), this.props.edit && this.props.edit.enabled) {
                  var F = i + "_" + t;
                  this.slider[F] = [], _ = o.default.createElement("div", {
                    className: "slider-container",
                    onMouseEnter: function onMouseEnter() {
                      h.setHover(F, !0);
                    },
                    onMouseLeave: function onMouseLeave() {
                      h.setHover(F, !1);
                    }
                  }, o.default.createElement(d.default, {
                    ref: function ref(e) {
                      return h.slider[F] = e;
                    },
                    slidesToShow: "1",
                    slidesToScroll: "1",
                    fade: !0,
                    infinite: !0,
                    initialSlide: H,
                    adaptiveHeight: !0,
                    className: S + " " + (this.state.hover[F] ? "hover" : ""),
                    beforeChange: function beforeChange(e, r) {
                      setTimeout(function () {
                        h.setContent(i, l, t, W[r].id);
                      }, 250);
                    },
                    nextArrow: o.default.createElement(y, null),
                    prevArrow: o.default.createElement(w, null)
                  }, W.map(function (e, t) {
                    if (m.embed) {
                      var r = o.default.createElement("img", {
                        src: (0, c.imageUrl)("general/play.png"),
                        width: "75",
                        className: "play-button",
                        alt: "Play video"
                      });
                      return o.default.createElement("div", {
                        className: "player-wrapper"
                      }, o.default.createElement(f.default, {
                        className: "player",
                        url: e.url,
                        playing: !0,
                        controls: !0,
                        width: "100%",
                        height: "100%",
                        light: (0, c.imageUrl)(e.thumbnail),
                        config: {
                          youtube: {
                            playerVars: {
                              modestbranding: 1
                            }
                          },
                          vimeo: {
                            title: !1
                          }
                        },
                        playIcon: r
                      }));
                    }

                    return o.default.createElement("a", {
                      href: "" + h.props.branding.url_hub + e.page,
                      key: F + "_" + t
                    }, o.default.createElement("img", {
                      src: e.thumbnail,
                      alt: ""
                    }));
                  })));
                } else if (!1 !== H) {
                  if (C.style && delete C.style, C.alt || (C.alt = ""), m.embed) {
                    var A = o.default.createElement("img", {
                      src: (0, c.imageUrl)("general/play.png"),
                      width: "75",
                      className: "play-button",
                      alt: "Play video"
                    });
                    _ = o.default.createElement("div", {
                      className: "player-wrapper"
                    }, o.default.createElement(f.default, {
                      className: "player",
                      url: W[H].url,
                      playing: !0,
                      controls: !0,
                      width: "100%",
                      height: "100%",
                      light: (0, c.imageUrl)(W[H].thumbnail),
                      config: {
                        youtube: {
                          playerVars: {
                            modestbranding: 1
                          }
                        },
                        vimeo: {
                          title: !1
                        }
                      },
                      playIcon: A
                    }));
                  } else _ = o.default.createElement("a", {
                    href: "" + this.props.branding.url_hub + W[H].page
                  }, o.default.createElement("img", {
                    src: W[H].thumbnail,
                    alt: ""
                  }));

                  W[H].placeholder && (_ = o.default.createElement("div", {
                    className: "placeholder_container"
                  }, _, o.default.createElement("div", {
                    className: "placeholder_label"
                  }, W[H].placeholder)));
                }

                N.push(_);
                break;

              case "text":
                if ((j = j.replace(/(?:\r\n|\r|\n)/g, "<br/>")) && m.max && (j = x(j, m.max)), this.props.edit && this.props.edit.enabled) {
                  var J = "",
                      D = {
                    buttons: ["bold", "italic", "underline"]
                  };
                  void 0 !== m.html && (m.html || (J = "textOnly", !1, D = !1)), _ = o.default.createElement(u.default, {
                    text: j,
                    className: J,
                    options: {
                      toolbar: D,
                      placeholder: {
                        text: m.placeholder ? m.placeholder : "Enter text"
                      },
                      contentWindow: document.getElementById("previewIframe").contentWindow,
                      ownerDocument: document.getElementById("previewIframe").contentWindow.document,
                      elementsContainer: document.getElementById("previewIframe").contentWindow.document.body
                    },
                    onChange: function onChange(e, r) {
                      m.max && (e = x(e, m.max)), h.setContent(i, l, t, e);
                    }
                  });
                } else j && (_ = this.renderHtml(E(j)));

                N.push(_);
                break;

              case "repeat":
                var U = [];
                U = m.settings.perrow.max ? p.default.chunk(j, m.settings.perrow.max) : [j], p.default.forEach(U, function (e, r) {
                  var n = !1;
                  n || p.default.forEach(m.settings.perrow.iteration, function (t, a) {
                    "odd" === a || "even" === a ? r % 2 == 0 ? "even" === a && (n = t.block) : "odd" === a && (n = t.block) : a <= e.length && (n = t.block);
                  });
                  var s = 2;
                  m.settings.perrow.max && (s = m.settings.perrow.max);
                  var c = 1,
                      u = [];
                  m.settings.perrow.max && m.settings.perrow.max > e.length && (c = m.settings.perrow.max, s = 1), p.default.forEach(e, function (e, f) {
                    var g = i + "_" + t + "_" + r + "_" + f;
                    h.props.edit && (h.slider[g] = []);
                    var v = h.props.template.blocks[n],
                        b = JSON.parse(v.settings),
                        E = document.createElement("tr"),
                        k = v.html;
                    E.innerHTML = k, k = E.firstChild.innerHTML;
                    var x = 0,
                        N = "",
                        C = !1;
                    h.props.edit ? (h.props.items[m.source] && (x = parseFloat(p.default.findKey(h.props.items[m.source], {
                      id: e
                    }))), C = h.props.items[m.source][x]) : C = e, N = h.props.edit && h.props.edit.enabled ? o.default.createElement("div", {
                      className: "grid slider-container " + S,
                      onMouseEnter: function onMouseEnter() {
                        h.setHover(g, !0);
                      },
                      onMouseLeave: function onMouseLeave() {
                        h.setHover(g, !1);
                      }
                    }, o.default.createElement(d.default, {
                      ref: function ref(e) {
                        return h.slider[g] = e;
                      },
                      slidesToShow: "1",
                      slidesToScroll: "1",
                      fade: !0,
                      infinite: !0,
                      initialSlide: x,
                      adaptiveHeight: !0,
                      className: S + " " + (h.state.hover[g] ? "hover" : ""),
                      beforeChange: function beforeChange(e, r) {
                        setTimeout(function () {
                          var e = h.props.data.sections[i].data[l][t];
                          e[f] = h.props.items[m.source][r].id, h.setContent(i, l, t, e);
                        }, 250);
                      },
                      nextArrow: o.default.createElement(y, null),
                      prevArrow: o.default.createElement(w, null)
                    }, h.props.items[m.source].map(function (e, r) {
                      return o.default.createElement("div", {
                        className: "item",
                        key: g + "_" + r
                      }, j.length && o.default.createElement("i", {
                        className: "delete fas fa-trash-alt click-allowed",
                        onClick: function onClick() {
                          var e = h.props.data.sections[i].data[l][t];
                          e.splice(f, 1), h.setContent(i, l, t, e);
                        }
                      }), h.parseHtml(k, i, l, b.data, e, !1, g));
                    }))) : o.default.createElement("div", {
                      className: "grid slider-container " + S
                    }, o.default.createElement("div", {
                      className: "item"
                    }, h.parseHtml(k, i, l, b.data, C, !1, g)));
                    var O = {};

                    switch (p.default.forEach(E.firstChild.attributes, function (e, t) {
                      O[e.nodeName] = h.renderBranding(e.nodeValue);
                    }), E.firstChild.tagName.toLowerCase()) {
                      case "td":
                      case "th":
                        O.colspan || (O.colspan = ""), O.colspan = c, _ = "th" === E.firstChild.tagName.toLowerCase() ? o.default.createElement("th", a({
                          key: g
                        }, O), N) : o.default.createElement("td", a({
                          key: g
                        }, O), N);
                        break;

                      default:
                        var P = "cols-" + s;
                        O.className && (P = O.className + " " + P), _ = o.default.createElement("li", a({
                          key: g
                        }, O, {
                          className: P
                        }), N);
                    }

                    u.push(_);
                  }), N.push(u);
                });
            }
          }

          return !s || N;
        }
      }, {
        key: "renderOptions",
        value: function value(e) {
          var t = this,
              r = [],
              a = this.props.data.sections[e],
              n = JSON.parse(this.props.template.settings);
          p.default.forEach(n.sections[a.section].blocks, function (a, n) {
            p.default.forEach(a, function (a, s) {
              switch (a.type) {
                case "repeat":
                  r.push({
                    icon: "fa-plus",
                    onClick: function onClick() {
                      var r = t.props.data.sections[e].data[n][a.source];
                      r.push(t.props.items[a.source][0].id), t.setContent(e, n, a.source, r);
                    }
                  });
              }
            });
          });
          return n.sections[a.section].config && r.push({
            icon: "fa-cog",
            onClick: function onClick() {
              var r = "";
              p.default.forEach(n.sections[a.section].config, function (a, n) {
                switch (a.type) {
                  case "select":
                    var s = !1;
                    a.default && (s = a.default), t.props.data.sections[e].config && t.props.data.sections[e].config[n] && (s = t.props.data.sections[e].config[n]), r = o.default.createElement(o.default.Fragment, null, r, o.default.createElement("label", {
                      className: "c-field__label"
                    }, a.label), o.default.createElement("select", {
                      onChange: function onChange(r) {
                        t.setConfig(e, n, r.target.value);
                      },
                      className: "u-mb-small c-input"
                    }, p.default.map(a.options, function (e, t) {
                      return o.default.createElement("option", {
                        value: e.value,
                        key: t,
                        selected: !(!s || s !== e.value)
                      }, e.label);
                    })));
                }
              }), x.fire({
                title: "Settings",
                html: r,
                showCancelButton: !1,
                confirmButtonText: "Finish",
                focusConfirm: !1
              }).then(function (e) {
                e.value;
              });
            }
          }), o.default.createElement(o.default.Fragment, null, o.default.createElement("div", {
            className: "edit-options"
          }, this.state.hover.section === e && r.length > 0 && o.default.createElement("div", {
            className: "section-general"
          }, r.map(function (e, t) {
            return o.default.createElement("i", {
              key: t,
              className: "fas " + e.icon + " click-allowed",
              onClick: e.onClick
            });
          })), !1));
        }
      }, {
        key: "renderHtml",
        value: function value(e) {
          var t = new k(),
              r = [{
            shouldProcessNode: function shouldProcessNode(e) {
              return !0;
            },
            processNode: new w.ProcessNodeDefinitions(o.default).processDefaultNode
          }];
          return t.parseWithInstructions(e, function () {
            return !0;
          }, r);
        }
      }, {
        key: "parseHtml",
        value: function value(e, t, r, a, n, s, i) {
          var l = this;
          e = this.renderBranding(e), e = this.renderCSS(e), e = (e = this.renderStatic(e, n)).replace(/>\s+</g, "><");

          for (var c = void 0, u = [], d = this, f = /data-element=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/gim; null !== (c = f.exec(e));) {
            c.index === f.lastIndex && f.lastIndex++, c.forEach(function (e, s) {
              1 === s && u.push({
                replaceChildren: !1,
                shouldProcessNode: function shouldProcessNode(s) {
                  var i = !1;
                  return d.renderElement("display", e, n, s, t, r, a) && (i = !0), s.attribs && s.attribs["data-element"] === e && i;
                },
                processNode: function processNode(s, l, c) {
                  var u = [],
                      p = {
                    key: e + "_" + c
                  };
                  return i && (p.key = i + "_" + p.key), s.attribs && s.attribs.class && u.push(s.attribs.class), d.props.edit && d.props.edit.errors.includes(e) && u.push("error"), u.length > 0 && (p.className = u.join(" ")), d.renderElement("replace", e, n, s, t, r, a).map(function (e, t) {
                    return o.default.createElement(s.tagName, p, e);
                  });
                }
              });
            });
          }

          var m = new k(),
              h = new w.ProcessNodeDefinitions(o.default);
          u.push({
            shouldProcessNode: function shouldProcessNode(e) {
              return !0;
            },
            processNode: h.processDefaultNode
          });
          var g = m.parseWithInstructions(e, function () {
            return !0;
          }, u),
              v = "";

          if (this.props.data.sections && this.props.data.sections[t]) {
            var b = JSON.parse(this.props.template.settings),
                y = this.props.data.sections[t].section;
            b.sections[y] && b.sections[y].config && p.default.forEach(b.sections[y].config, function (e, r) {
              var a = !1;
              l.props.data.sections[t].config && l.props.data.sections[t].config[r] ? a = p.default.filter(e.options, {
                value: l.props.data.sections[t].config[r]
              }) : e.default && (a = p.default.filter(e.options, {
                value: e.default
              })), a && a[0].class && (v = v + " " + a[0].class);
            });
          }

          if (this.props.edit && this.props.edit.enabled && s) {
            var E = "";
            return this.state.hover.section && (E = this.state.hover.section === t ? "hover" : "opaque"), o.default.createElement("section", {
              onMouseEnter: function onMouseEnter() {
                l.setHover("section", t);
              },
              onMouseLeave: function onMouseLeave() {
                l.setHover("section", !1);
              },
              className: E + " " + v
            }, this.renderOptions(t), g);
          }

          return "" !== v ? o.default.createElement("div", {
            className: v
          }, g) : o.default.createElement(o.default.Fragment, null, g);
        }
      }, {
        key: "getBlockSettings",
        value: function value(e, t) {
          var r = JSON.parse(this.props.template.settings),
              a = this.props.template.blocks[e],
              n = r.sections[t].blocks[e];
          return a.settings && p.default.forEach(JSON.parse(a.settings), function (e, t) {
            n[t] ? n[t] = Object.assign(n[t], e) : n[t] = e;
          }), n;
        }
      }, {
        key: "render",
        value: function value() {
          var e = this,
              t = JSON.parse(this.props.template.settings),
              r = this.props.data,
              a = [],
              n = [];
          this.props.template.css.length > 0 && n.push(this.props.template.css);
          var s = r.sections;
          p.default.forEach(s, function (r, s) {
            p.default.forEach(r.data, function (i, l) {
              var c = e.props.template.blocks[l],
                  u = e.getBlockSettings(l, r.section),
                  d = c.html,
                  p = void 0,
                  f = e,
                  m = !0,
                  h = !1;
              t.sections[r.section].fixed || function () {
                var e = /data-element=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/gim,
                    t = [];
                if (null !== e.exec(d)) for (e = /data-element=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/gim; null !== (p = e.exec(d));) {
                  m = !0, p.index === e.lastIndex && e.lastIndex++, p.forEach(function (e, a) {
                    1 === a && t.push({
                      replaceChildren: !1,
                      shouldProcessNode: function shouldProcessNode(t) {
                        var a = !1;
                        return f.renderElement("display", e, !1, t, s, l, u) ? a = !0 : u[r.section] && u[r.section].fallback && (a = !0, h = u[r.section].fallback), t.attribs && t.attribs["data-element"] === e && !a;
                      },
                      processNode: function processNode(e, t, r) {
                        return m = !1, "";
                      }
                    });
                  });
                } else m = !0;
                var a = new k(),
                    n = new w.ProcessNodeDefinitions(o.default);
                t.push({
                  shouldProcessNode: function shouldProcessNode(e) {
                    return !0;
                  },
                  processNode: n.processDefaultNode
                }), a.parseWithInstructions(d, function () {
                  return !0;
                }, t);
              }(), m && (h && (c = e.props.template.blocks[h], u = e.getBlockSettings(h, r.section), d = c.html), "" !== c.css && n.push(c.css), a.push(e.parseHtml(d, s, l, u, !1, r.section, s)));
            });
          });
          var i = "";
          return this.props.child || (i += '<link href="' + this.props.branding.font_heading.url + '" rel="stylesheet">', this.props.branding.font_heading.id !== this.props.branding.font_text.id && (i += '<link href="' + this.props.branding.font_text.url + '" rel="stylesheet">'), this.props.edit && this.props.edit.enabled && (i += '<link href="//cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" type="text/css" media="all" rel="stylesheet">', i += '<link href="//cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick.min.css" type="text/css" media="all" rel="stylesheet">', i += '<link href="//cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick-theme.css" type="text/css" media="all" rel="stylesheet">', i += '<link href="//cdnjs.cloudflare.com/ajax/libs/medium-editor/5.23.3/css/medium-editor.min.css" type="text/css" media="all" rel="stylesheet">', i += '<link href="//cdnjs.cloudflare.com/ajax/libs/medium-editor/5.23.3/css/themes/default.min.css" type="text/css" media="all" rel="stylesheet">')), n.length > 0 && (n = this.renderCSS(n.join("\r\n")), i += '<style type="text/css"> ' + n + " </style>"), this.props.css && this.props.css.forEach(function (e) {
            i += '<link href="' + e + '" type="text/css" media="all" rel="stylesheet">';
          }), a.unshift(this.parseHtml(i)), a;
        }
      }]), t;
    }(l.Component);

    t.default = N;
  }, function (t, r) {
    t.exports = e;
  }, function (e, r) {
    e.exports = t;
  }, function (e, t, r) {
    "use strict";

    function a(e) {
      return n(e) ? e : "https://campaign-tool.s3.eu-west-2.amazonaws.com/" + e;
    }

    function n(e) {
      return new RegExp("^(https?)(://|(%3A%2F%2F))", "i").test(e);
    }

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.imageUrl = a, t.imageResize = function (e, t, r, a, s) {
      if (n(e)) return e;
      t || (t = null);
      r || (r = null);
      a || (a = "cover");
      var i = {
        bucket: "campaign-tool",
        key: e,
        edits: {
          resize: {
            width: t,
            height: r,
            withoutEnlargement: !1,
            fit: a,
            position: "attention",
            background: "#FFF"
          }
        }
      };
      if (s) switch (s) {
        case "play":
          i.edits.overlayWith = {
            bucket: "campaign-tool",
            key: "general/play.png",
            wRatio: 15
          };
      }
      return "https://d24fvwhp25tn1r.cloudfront.net/" + btoa(JSON.stringify(i));
    }, t.checkUrl = n, t.default = a;
  }, function (e, t) {
    e.exports = r;
  }, function (e, t) {
    e.exports = a;
  }, function (e, t) {
    e.exports = n;
  }, function (e, t) {
    e.exports = s;
  }, function (e, t) {
    e.exports = i;
  }, function (e, t) {
    e.exports = l;
  }, function (e, t) {
    e.exports = o;
  }, function (e, t) {
    e.exports = c;
  }, function (e, t) {
    e.exports = u;
  }, function (e, t) {
    e.exports = d;
  }]);
});