import { hubConstants } from '../constants/hub';
import _ from 'lodash';

const defaultState = { articles: {}, details: false };

export default function(state = defaultState, action) {
		
	switch (action.type) {				
		      
		case hubConstants.SLIDER_GET:
										
			return { 
				...state, 
				slider: action.payload.data.slider
			}
			
		case hubConstants.ADVERT_GET:
										
			return { 
				...state, 
				advert: action.payload.data.advert
			}
			
		case hubConstants.PAGE_GET:
			
			let current = state;
			
			if(!current[action.page]){
				current[action.page] = [];
			}
			
			current[action.page] = action.payload.data;
								
			return { 
				...state, 
				...current
			}
			
		case hubConstants.PREVIEW_GET:
						
			return { 
				...state, 
				preview: action.payload.data
			}
			
		default:
			return state;
	}
}


