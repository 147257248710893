import { subscribeConstants } from '../constants/subscription';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';

export function settingsGet(callback){

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get('/hub/settings/subscription');
										
			dispatch({ 
				type: subscribeConstants.SETTINGS,
				payload: request
			});

			if(callback)
				callback();
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function subscribe(formName, values, callback){

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().post('/hub/subscribe', values);
										
			dispatch({ 
				type: subscribeConstants.SUBSCRIBE,
				payload: request
			});
			
			if(callback)
				callback(request.data.status);
				
			dispatch(stopSubmission(formName));
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function subscribeConfirm(token, callback){

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().post('/hub/subscribe', { token: token });
										
			dispatch({ 
				type: subscribeConstants.SUBSCRIBE_CONFIRM,
				payload: request
			});
			
			if(callback)
				callback(request.data.status);
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function unsubscribe(formName, email, callback){

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().post('/hub/unsubscribe', {email: email});
										
			dispatch({ 
				type: subscribeConstants.UNSUBSCRIBE,
				payload: request
			});
			
			if(callback)
				callback(request.data.status);
				
			dispatch(stopSubmission(formName));
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}