import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SliderElmComponent from '../../components/chrome/slider'; 
import { imageResize } from '@bit/dinomedia.objective1-campaign.objective1-campaign-s3';
import { Link } from 'react-router-dom';
import Template from '@bit/dinomedia.objective1-campaign.objective1-campaign-event';

import { pageGet } from '../../actions/hub';

class Article extends Component {

	constructor(props){
		super(props);		
		
		const { url } = this.props.match.params;
						
		if(!url){
			this.props.history.push('/articles');
		}
		
		this.state = {
			id: false
		}
		
		// ALWAYS CALL REGARDLESS, IN CASE STALE
		this.props.pageGet('hub_article', { url: url}, false, () => {			
			this.props.history.push('/');
		});
	}
	
	render() {
				
		if(!this.props.hub.hub_article || !this.props.brand.name ){
			return '';
		}
		
		const { url } = this.props.match.params;
			
		return (
			<Template 
				template={this.props.hub.hub_article.template}
				branding={this.props.brand}
				//items={item.items}
				data={this.props.hub.hub_article.data}
			/>
		);
	}
}

function mapStateToProps({ hub, brand }, ownProps){
	return {
		hub,
		brand
	};
}

export default connect(mapStateToProps, { 
	pageGet
})(Article);