import React, { Component } from 'react';
import { connect } from 'react-redux';
import Template from '@bit/dinomedia.objective1-campaign.objective1-campaign-event';
import Slider from "react-slick";
import _ from 'lodash';

import { trackEvent } from '../../actions/tracking';

class SliderElmComponent extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			slide: 0
		}
	}
	
	componentDidMount(){
		window.addEventListener('click', this.trackClicks.bind(this));
	}
	
	componentWillUnmount(){
		window.removeEventListener('click', this.trackClicks.bind(this));
	}
	
	trackClicks(e){
		
		let target = e.target;
		let url = false;

		// MAKE SURE NOT CLICKING ON ARROW!
		if(!target.classList.contains('slick-arrow')){
		
			// GET THE URL
			while (target !== undefined && target.localName && target.localName !== 'a') {
				target = target.parentNode;
			}			
			
			if(target !== undefined) {
				
				url = target.href;
						
				// MAKE SURE IS CHILD OF REQUIRED ELEMENT
				while(target !== undefined && target !== null && target.classList && !target.classList.contains('slick-slide')) {
					target = target.parentNode;
				}	
							
				if(target !== undefined && target !== null && target.classList && target.classList.contains('slick-slide')){
					
					e.preventDefault();
					
					this.props.trackEvent(this.props.items[this.state.slide].id.event, 'click', () => {
						window.open(url);
					});	
				}
			}
		}

		return true;
	}
		
	render() {
		
		// TRACK IMPRESSION!
		this.props.trackEvent(this.props.items[this.state.slide].id.event, 'impression');		
				
		return (
			<React.Fragment>
				<link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick.min.css" media="all" rel="stylesheet" />
				<link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick-theme.css" media="all" rel="stylesheet" />
				
				<Slider 
					slidesToShow="1"
					slidesToScroll="1"
					fade={true}
					infinite={false}
					adaptiveHeight={true}
					autoPlay={true}
					autoPlaySpeed={4000}
					beforeChange={(current, next) => {
						
					}}
					afterChange={(current) => {
						this.setState({
							slide: current
						});
					}}
					
				>
					{_.map(this.props.items, (item, key) => {
									
						return (
							<div key={key}>
								<Template 
									template={item.template}
									branding={this.props.brand}
									items={item.items}
									data={item.data}
								/>
							</div>
						);
					})}
				</Slider>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ }, ownProps){
	return {

	};
}

export default connect(mapStateToProps, { 
	trackEvent
})(SliderElmComponent);