import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SliderElmComponent from '../../components/chrome/slider'; 
import { imageResize } from '@bit/dinomedia.objective1-campaign.objective1-campaign-s3';
import { Link } from 'react-router-dom';
import Template from '@bit/dinomedia.objective1-campaign.objective1-campaign-event';

import { pageGet } from '../../actions/hub';

class Hub extends Component {

	constructor(props){
		super(props);		
		
		this.props.pageGet('hub_index', {});
	}
	
	render() {
				
		if(!this.props.hub.hub_index || !this.props.brand.name){
			return '';
		}
					
		return (
			<Template 
				template={this.props.hub.hub_index.template}
				branding={this.props.brand}
				//items={item.items}
				data={this.props.hub.hub_index.data}
			/>
		);
	}
}

function mapStateToProps({ hub, brand }, ownProps){
	return {
		hub,
		brand
	};
}

export default connect(mapStateToProps, { 
	pageGet
})(Hub);