import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import Submit from '../../components/forms/submit';

import { settingsGet, subscribe } from '../../actions/subscription';
import { startSubmission, stopSubmission } from '../../actions/forms';

var formName = 'formSubscribe';

class Subscribe extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			privacy: false
		}
		
		this.props.stopSubmission(formName);
		this.props.settingsGet();
	}
	
	onSubmit(values) {
			  				  	
	  	this.props.startSubmission(formName);

		this.props.subscribe(formName, values, (status) => {
			
			switch(status){
				
				case 'success_double_optin':
					alert(i18n.t('subscribe_success_double_optin'));
					break;
					
				case 'success':
					alert(i18n.t('subscribe_success_added'));
					break;
					
				case 'exists':
					alert(i18n.t('subscribe_failed_exists'));
					break;
					
				default:
				
					break;
			}
	  	});
	}
	
	onInputChangePrivacy(event){
		this.setState({ 
			privacy: event.target.checked 
		});
	}
	
	render() {
		
		if(!this.props.subscription.settings || !this.props.brand.name){
			return '';
		}
		
		const settings = this.props.subscription.settings;
		const { handleSubmit } = this.props;
		
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>								
				
				<Field
					label={i18n.t('subscribe_email')} 
					name="email"
					type="email"
					component={InputField}
				/>
				
				{settings.name && 
					
					<React.Fragment>
					
						<Field
							label={i18n.t('subscribe_forename')} 
							name="forename"
							type="text"
							component={InputField}
						/>
						
						<Field
							label={i18n.t('subscribe_surname')} 
							name="surname"
							type="text"
							component={InputField}
						/>
					</React.Fragment>
				}
				
				{settings.dob && 
					
					<Field
						label={i18n.t('subscribe_dob')} 
						name="dob"
						type="date"
						component={InputField}
					/>
				}
				
				{settings.gender && 
					
					<Field
						label={i18n.t('subscribe_gender')} 
						name="gender"
						type="select"
						options={[
							{
								value: '',
								label: ''
							},
							{
								value: 'male',
								label: i18n.t('subscribe_gender_option_male')
							},
							{
								value: 'female',
								label: i18n.t('subscribe_gender_option_female')
							},
							{
								value: 'other',
								label: i18n.t('subscribe_gender_option_other')
							}
						]}
						component={InputField}
					/>
				}
				
				{settings.privacy && 
					<Field
						name="privacy"
						type="checkbox"
						options={[
							{
								label: (
									<span dangerouslySetInnerHTML={
									    {__html: i18n.t('subscribe_privacy', { url: settings.privacy, interpolation: {escapeValue: false}} )}
									} />
								),
								value: 1
							}
						]}
						checked={this.state.privacy}
						onChangeFunc={this.onInputChangePrivacy.bind(this)}
						component={InputField}
					/>
				}
				
				<Submit 
					label={i18n.t('subscribe_submit')} 
					form={formName} 
				/>
				
			</form>
		);
	}
}

const validate = (values) => {	
	
	const errors = {};

	if (!values.forename) {
		errors.forename = 'Please enter your forename!';
	}
	
	if (!values.surname) {
		errors.surname = 'Please enter your surname!';
	}
	
	if (!values.dob) {
		errors.dob = 'Please enter your dob!';
	}
	
	if (!values.email) {
		errors.email = 'Please enter your email!';
	}
	
	if (!values.privacy) {
		errors.privacy = 'Please agree to our privacy policy!';
	}
	
	return errors;
}

function mapStateToProps({ brand, subscription }, ownProps){
	return {
		brand,
		subscription
	};
}

export default connect(
	mapStateToProps, {
		settingsGet,
		stopSubmission,
		startSubmission,
		subscribe
	})(
	reduxForm({
		validate,
		form: formName
	})(Subscribe)
);