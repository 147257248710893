import React, { Component } from 'react';
import { connect } from 'react-redux';
import Template from '@bit/dinomedia.objective1-campaign.objective1-campaign-event';
import { trackEvent } from '../../actions/tracking';

import { pageGet } from '../../actions/hub';

class Landing extends Component {

	constructor(props){
		super(props);
		
		const { url } = this.props.match.params;
		
		if(!url){
			this.props.history.push('/');
		}
		
		this.props.pageGet('landing', { url: url }, false, () => {
			this.props.history.push('/');
		});
	}
	
	componentDidMount(){
		window.addEventListener('click', this.trackClicks.bind(this));
	}
	
	componentWillUnmount(){
		window.removeEventListener('click', this.trackClicks.bind(this));
	}
	
	trackClicks(e){
		
		let target = e.target;
		let url = false;
		
		// GET THE URL
		while (target !== undefined && target.localName && target.localName !== 'a') {
			target = target.parentNode;
		}			
		
		if(target !== undefined) {
			
			url = target.href;
					
			e.preventDefault();
				
			this.props.trackEvent(this.props.hub.landing.id.event, 'click', () => {
				window.location = url;
			});	
		}

		return true;
	}
	
	render() {
				
		if(!this.props.hub.landing || !this.props.brand.name){
			return '';
		}
				
		return (
			<Template 
				template={this.props.hub.landing.template}
				branding={this.props.brand}
				//items={item.items}
				data={this.props.hub.landing.data}
			/>
		);
	}
}

function mapStateToProps({ hub, brand }, ownProps){
	return {
		hub,
		brand
	};
}

export default connect(mapStateToProps, { 
	pageGet,
	trackEvent
})(Landing);