import axios from 'axios';

export const key = '68E8172B46D4E84B2D8B';
export let url = 'https://api.campaign.pushretail.io';

if(process.env.NODE_ENV === 'development'){
	url = 'http://127.0.0.1:8000';
}else if(process.env.NODE_ENV === 'development' || window.location.hostname.indexOf('staging') > -1){
	url = 'https://api.staging.campaign.pushretail.io';
}

export function axiosCreate() {
	
	var config = {
		baseURL: url,
		headers: {
			'API-KEY': key,
			'APP-HOST': window.location.hostname
		}
	}

	return axios.create(config);
}