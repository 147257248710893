import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import MetaTags from 'react-meta-tags';

class Holding extends Component {

	constructor(props){
		super(props);	
		
		if(window.location.pathname !== "/"){
			window.location = 'https://cyclingcampaign.com';
		}	
	}
	
	render() {
				
		return (
			<React.Fragment>
				<MetaTags>
					<title>Cycling Campaign</title>
				</MetaTags>
				
				<h1>Cycling Campaign</h1>
			
			</React.Fragment>
		);
	}
}

function mapStateToProps({ }, ownProps){
	return {

	};
}

export default connect(mapStateToProps, { 

})(Holding);