import { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 

import { subscribeConfirm } from '../../actions/subscription';

class SubscribeConfirm extends Component {

	constructor(props){
		super(props);
		
		const { token } = this.props.match.params;
		
		if(!token){
			this.props.history.push('/');
		}
		
		this.props.subscribeConfirm(token, (status) => {
			
			switch(status){

				case 'success':
					alert(i18n.t('subscribe_success_added'));
					break;
					
				case 'exists':
					alert(i18n.t('subscribe_failed_exists'));
					break;
					
				default:
				
					break;
			}
			
			this.props.history.push('/');
	  	});
	}
	
	render() {
		return '';
	}
}

function mapStateToProps({ brand, subscription }, ownProps){
	return {
		brand,
		subscription
	};
}

export default connect(mapStateToProps, { 
	subscribeConfirm
})(SubscribeConfirm);