!function (e, t) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = t();else if ("function" == typeof define && define.amd) define([], t);else {
    var n = t();

    for (var r in n) {
      ("object" == typeof exports ? exports : e)[r] = n[r];
    }
  }
}(window, function () {
  return function (e) {
    var t = {};

    function n(r) {
      if (t[r]) return t[r].exports;
      var o = t[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(o.exports, o, o.exports, n), o.l = !0, o.exports;
    }

    return n.m = e, n.c = t, n.d = function (e, t, r) {
      n.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: r
      });
    }, n.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, n.t = function (e, t) {
      if (1 & t && (e = n(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var r = Object.create(null);
      if (n.r(r), Object.defineProperty(r, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var o in e) {
        n.d(r, o, function (t) {
          return e[t];
        }.bind(null, o));
      }
      return r;
    }, n.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return n.d(t, "a", t), t;
    }, n.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, n.p = "", n(n.s = 0);
  }([function (e, t, n) {
    "use strict";

    function r(e) {
      return o(e) ? e : "https://campaign-tool.s3.eu-west-2.amazonaws.com/" + e;
    }

    function o(e) {
      return new RegExp("^(https?)(://|(%3A%2F%2F))", "i").test(e);
    }

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.imageUrl = r, t.imageResize = function (e, t, n, r, i) {
      if (o(e)) return e;
      t || (t = null);
      n || (n = null);
      r || (r = "cover");
      var u = {
        bucket: "campaign-tool",
        key: e,
        edits: {
          resize: {
            width: t,
            height: n,
            withoutEnlargement: !1,
            fit: r,
            position: "attention",
            background: "#FFF"
          }
        }
      };
      if (i) switch (i) {
        case "play":
          u.edits.overlayWith = {
            bucket: "campaign-tool",
            key: "general/play.png",
            wRatio: 15
          };
      }
      return "https://d24fvwhp25tn1r.cloudfront.net/" + btoa(JSON.stringify(u));
    }, t.checkUrl = o, t.default = r;
  }]);
});