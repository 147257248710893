import React, { Component } from 'react';
import { connect } from 'react-redux';
import Template from '@bit/dinomedia.objective1-campaign.objective1-campaign-event';
import { Base64 } from 'js-base64';

import { pageGet } from '../../actions/hub';

class Video extends Component {

	constructor(props){
		super(props);
		
		let { token } = this.props.match.params;
				
		if(!token){
			this.props.history.push('/');
		}
		
		let details = JSON.parse(Base64.decode(token));
				
		if(!details.event || !details.video){
			this.props.history.push('/');
		}
		
		this.props.pageGet('hub_video', { event: details.event, id: details.video }, false, () => {
			this.props.history.push('/');
		});
	}
	
	render() {
		
		if(!this.props.hub.hub_video || !this.props.brand.name){
			return '';
		}
						
		return (
			<Template 
				template={this.props.hub.hub_video.template}
				branding={this.props.brand}
				//items={item.items}
				data={this.props.hub.hub_video.data}
			/>
		);
	}
}

function mapStateToProps({ hub, brand }, ownProps){
	return {
		hub,
		brand
	};
}

export default connect(mapStateToProps, { 
	pageGet
})(Video);