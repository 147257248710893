import { trackingConstants } from '../constants/tracking';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
const localIpUrl = require('local-ip-url');

export function trackEvent(event_id, type, callback){

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().post('/tracking/track', { id: event_id, type: type, url: window.location.href, ip: localIpUrl(), agent: navigator.userAgent });
										
			dispatch({ 
				type: trackingConstants.IMPRESSION,
				payload: request
			});
			
			if(callback)
				callback();

		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}