import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import Favicon from 'react-favicon';
import { brandGet } from '../../actions/brand';
import { imageResize } from '@bit/dinomedia.objective1-campaign.objective1-campaign-s3';

class Head extends Component {

	constructor(props){
		super(props);
		
		if(!["cyclingcampaign.com", "staging.cyclingcampaign.com","preview.cyclingcampaign.com","preview.staging.cyclingcampaign.com"].includes(window.location.hostname) && !this.props.brand.name){
			this.props.brandGet();
		}
	}
	
	render() {
		
		let favicon = false;
		
		if(this.props.brand.icon){
			favicon = imageResize(this.props.brand.icon, 500);
		}
		
		let title = this.props.brand.name ? this.props.brand.name : 'Loading';
		let description = false;
		let keywords = false;
		
		// TODO: We should pass meta from API
		if(this.props.meta){
			
			if(this.props.meta.title){
				title = this.props.meta.title;
			}
			
			if(this.props.meta.description){
				description = this.props.meta.description;
			}
			
			if(this.props.meta.keywords){
				keywords = this.props.meta.keywords;
			}
		}
				
		return (
			<React.Fragment>
				<MetaTags>
					<title>{title}</title>
					
					{description &&
						<meta name="description" content={description} />
					}
					
					{keywords &&
						<meta name="keywords" content={keywords} />
					}
				</MetaTags>
				<Favicon url={favicon} />
			</React.Fragment>
		);
	}
}

function mapStateToProps({ brand }, ownProps){
	return {
		brand
	};
}

export default connect(mapStateToProps, { 
	brandGet
})(Head);
