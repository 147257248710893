import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import Submit from '../../components/forms/submit';

import { unsubscribe } from '../../actions/subscription';
import { startSubmission, stopSubmission } from '../../actions/forms';

var formName = 'formUnsubscribe';

class Unsubscribe extends Component {

	constructor(props){
		super(props);
	
		this.props.stopSubmission(formName);
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		
		if(queryString.email){
			this.props.initialize({ email: queryString.email });
		}
	}
	
	onSubmit(values) {
			  				  	
	  	this.props.startSubmission(formName);

		this.props.unsubscribe(formName, values.email, (status) => {
			
			switch(status){

				case 'success':
					alert(i18n.t('unsubscribe_success'));
					this.props.history.push('/');
					break;
					
				case 'failed':
					alert(i18n.t('unsubscribe_failed'));
					break;
					
				default:
				
					break;
			}			
	  	});
	}

	render() {
		
		if(!this.props.brand.name){
			return '';
		}
		
		const { handleSubmit } = this.props;
		
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>								
				
				<Field
					label={i18n.t('unsubscribe_email')} 
					name="email"
					type="email"
					component={InputField}
				/>		
						
				<Submit 
					label={i18n.t('unsubscribe_submit')} 
					form={formName} 
				/>
				
			</form>
		);
	}
}

const validate = (values) => {	
	
	const errors = {};

	if (!values.email) {
		errors.email = 'Please enter your email!';
	}

	return errors;
}

function mapStateToProps({ brand, subscription }, ownProps){
	return {
		brand,
		subscription
	};
}

export default connect(
	mapStateToProps, {
		stopSubmission,
		startSubmission,
		unsubscribe
	})(
	reduxForm({
		validate,
		form: formName
	})(Unsubscribe)
);