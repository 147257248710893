import { formsConstants } from '../constants/forms';

export function startSubmission(form){
		
	return {
		type: formsConstants.SUBMITSTART,
		data: form
	};
}

export function stopSubmission(form){
		
	return {
		type: formsConstants.SUBMITSTOP,
		data: false
	};
}