import { brandConstants } from '../constants/brand';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function brandGet(){

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get('/hub/brand');
										
			dispatch({ 
				type: brandConstants.BRAND_GET,
				payload: request
			});
			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}