import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import HubReducer from './hub';
import BrandReducer from './brand';
import SubscriptionReducer from './subscription';
import ErrorsReducer from './errors';
import FormsReducer from './forms';

const appReducer = combineReducers({
	form: formReducer, 
	hub: HubReducer,
	brand: BrandReducer,
	subscription: SubscriptionReducer,
	forms: FormsReducer,
	errors: ErrorsReducer
});

const rootReducer = (state, action) => {
	return appReducer(state, action)
}

export default rootReducer;