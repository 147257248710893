import { brandConstants } from '../constants/brand';

export default function(state = {}, action) {
		
	switch (action.type) {				
		      
		case brandConstants.BRAND_GET:
										
			return { 
				...state, 
				...action.payload.data
			}
			
		default:
			return state;
	}
}