import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom"

class Submit extends Component {
	
	render() {
		
		let disabled = false;
		let className = '';
		
		if(this.props.className){
			className = this.props.className;
		}

		if(this.props.form && this.props.formSubmitting === this.props.form){
			//disabled = true;
			//className += ' processing ';
		}
		
		return (
			<button 
				type="submit" 
				className={`submit c-btn c-btn--fullwidth c-btn--info ${className}`} 
				disabled={disabled}
				onClick={() => {
					
					if(this.props.onClick){
						this.props.onClick();
					}
				}}
			>
				{this.props.label ? this.props.label : 'Submit'}
			</button>
		)
	}
}

function mapStateToProps({ forms }, ownProps){
	return { 
		formSubmitting: forms.submitting,
		forms: forms
	};
}

export default withRouter(connect(mapStateToProps, {  
	
})(Submit));
