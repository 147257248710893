import { errorsConstants } from '../constants/errors';

export default function(state = {}, action) {
		
	switch(action.type){		
		
		case errorsConstants.ERRORS_ERROR:		
					
			if(action.payload.status){
				
				switch(action.payload.status){
					
					case 'invalid_host':
					case 'invalid_hub':
						window.location = 'https://cyclingcampaign.com';
						break;
						
					case 'use_custom_domain':
						window.location.hostname = action.payload.domain;
						break;
				}
			}
			
		default:
			return state;
	}
}	
