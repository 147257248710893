import { hubConstants } from '../constants/hub';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function sliderGet(callback){

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get('/hub/slider');
										
			dispatch({ 
				type: hubConstants.SLIDER_GET,
				payload: request
			});

			if(callback)
				callback();
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function advertGet(callback, onError){

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get('/hub/advert');
										
			dispatch({ 
				type: hubConstants.ADVERT_GET,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			
			if(onError)
				onError();
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function pageGet(type, data, callback, onError){
		
	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get(`/hub/page/${type}`, { params: data });
										
			dispatch({ 
				type: hubConstants.PAGE_GET,
				payload: request,
				page: type
			});
			
			if(callback)
				callback();
			
		} catch(error) {
			
			if(onError)
				onError();
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function previewGet(token, onError){
		
	return async (dispatch) => {

		try {
			const request = await axiosCreate().get(`/hub/preview/${token}`);
										
			dispatch({ 
				type: hubConstants.PREVIEW_GET,
				payload: request
			});
			
		} catch(error) {
			
			if(onError)
				onError();
				
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}