import React, { Component } from 'react';
import { connect } from 'react-redux';
import SliderElmComponent from '../../components/chrome/slider'; 

import { sliderGet } from '../../actions/hub';

class SliderElm extends Component {

	constructor(props){
		super(props);

		this.props.sliderGet();
	}
		
	render() {
		
		if(!this.props.hub.slider || !this.props.brand.name){
			return '';
		}
		
		if(this.props.hub.slider.length > 0){
			
			return (
				<SliderElmComponent
					type="slider"
					items={this.props.hub.slider}
					brand={this.props.brand}
				/>
			);
		}
		
		return '';
	}
}

function mapStateToProps({ hub, brand }, ownProps){
	return {
		hub,
		brand
	};
}

export default connect(mapStateToProps, { 
	sliderGet
})(SliderElm);