import React, { Component } from 'react';
import _ from 'lodash';
import MaskedInput from 'react-input-mask';

export default class InputField extends Component {
	
	onInputChange(event) {		
				
		// ALWAYS CALL DEFAULT ONCHANGE HERE
        if(this.props.input){
	        this.props.input.onChange(event);
		}
		
        // THEN CALL THE CUSTOM ONCHANGE IF SET
        if(this.props.onChangeFunc){
	        this.props.onChangeFunc(event);
	    }
	}
	
	onInputBlur(event) {
				
		// ALWAYS CALL DEFAULT ONBLUR HERE
        if(this.props.input){
	        this.props.input.onBlur(event);
		}
		
        // THEN CALL THE CUSTOM ONBLUR IF SET
        if(this.props.onBlurFunc){
	        this.props.onBlurFunc(event);
	    }
	}
		
	renderInput(className) {
		
		let placeholder;
						
		switch (this.props.type) {

			case 'text':
			case 'email':
			case 'hidden':
			case 'number':
			case 'password':
			case 'url':
				
				return (
					<input
						{...this.props.input}
						type={this.props.type}
						disabled={this.props.disabled}
						placeholder={this.props.placeholder}
						className={`u-mb-small ${className} c-input`}
						onChange={this.onInputChange.bind(this)}
						onBlur={this.onInputBlur.bind(this)}
					/>
				);
				
			case 'select':
				
				return (
					<select
						{...this.props.input}
						disabled={this.props.disabled}
						className={`u-mb-small ${className} c-input`}
						onChange={this.onInputChange.bind(this)}
						onBlur={this.onInputBlur.bind(this)}
					>
						{_.map(this.props.options, option => {

							return (
								<option value={option.value}>{option.label}</option>
							);
						})}
					</select>
				);

			case 'date':
			
				placeholder = "DD/MM/YYYY";
				let mask = '12/34/9999';
				let newMask = mask;
				let splitValue = '';
				
				return (
					<MaskedInput
						{...this.props} 
						{...this.props.input}
						type="text"
						className={`u-mb-small ${className} c-input`}
						mask={mask}
						formatChars={{
						    '1': '[0-3]', // D1
						    '2': '[0-9]', // D2
						    '3': '[0-1]', // M1
						    '4': '[0-9]', // M2
						    
						    '5': '[0-1]', // D2 - if D1 starts with 3
						    '6': '[0-2]', // M2 - if M1 starts with 1
						    
						    '9': '[0-9]' // Y
						}}
						placeholder={placeholder}
						maskPlaceholder={placeholder}
						onChange={(event) => {
							
							var value = event.target.value;														
							newMask = mask;
							
							if(value !== ''){
								
								splitValue = value.split('/');
							
								if(splitValue[0].startsWith('3')){								
									newMask = newMask.substr(0,1) + '5' + newMask.substr(2);
								}
								
								if(splitValue[1].startsWith('1')){								
									newMask = newMask.substr(0,4) + '6' + newMask.substr(5);
								}
							}
							
							var newState = {
								mask: newMask,
								value: value
							};

							this.setState(newState);
						}}
						{...this.state}
					/>	
				);							
				
			case 'checkbox':

				let inputChecked = '';
				
				if(this.props.checked){
					inputChecked = 'checked';
				}
				
				return (
					<div className={className}>
						{_.map(this.props.options, option => {

							return (
								<div className="form-check" key={option.value}>
									<label className={`form-check-label ${inputChecked}`}>
										<input
											{...this.props.input}
											type="checkbox"
											className="form-check-input"
											value={option.value}
											checked={this.props.checked === true}
											onChange={this.onInputChange.bind(this)}
										/>
										{option.label}
									</label>
								</div>
							);
						})}
					</div>
				)
				
			default:
				return;
		}
	}

	render() {

		let label = '';
		let errorMessage = '';
		let touched = false;
		let error = false;
			
		if(this.props.meta){
			
			if(this.props.meta.touched){
				touched = this.props.meta.touched;
			}
			
			if(this.props.meta.error){
				error = this.props.meta.error;
			}
		}
		
		let className = `${touched && error ? 'c-input--danger' : ''}`;

		if(this.props.className){
			className = `${className} ${this.props.className}`;
		}
		
		if (this.props.label) {
			label = (
				<label className="c-field__label">
					{this.props.label}
				</label>
			);
		}

		if (this.props.errorMessage) {
			errorMessage = (
				<div className="invalid-feedback">
					{touched ? error : ''}
				</div>
			);
		}

		return (
			<div className={`c-field`}>
				{label !== '' ? label : null}
				
				<div className="field">
					{this.renderInput(className)}
				</div>
				
				{errorMessage !== '' ? errorMessage : null}
			</div>					
		);
	}
}