import { subscribeConstants } from '../constants/subscription';

const defaultState = { details: false };

export default function(state = defaultState, action) {
		
	switch (action.type) {				
		      
		case subscribeConstants.SETTINGS:
										
			return { 
				...state, 
				settings: action.payload.data
			}	
			
		default:
			return state;
	}
}