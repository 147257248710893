import React, { Component } from 'react';

class Error404 extends Component {
	
	render(){
		
		return (
			<div>
				404!
			</div>
		);
	}
}

export default Error404