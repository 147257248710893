import React, { Component } from 'react';
import { connect } from 'react-redux';
import SliderElmComponent from '../../components/chrome/slider'; 

import { advertGet } from '../../actions/hub';

class Advert extends Component {

	constructor(props){
		super(props);
		
		this.props.advertGet();
	}
	
	render() {
		
		if(!this.props.hub.advert || !this.props.brand.name){
			return '';
		}
				
		if(this.props.hub.advert.length > 0){
			
			return (
				<SliderElmComponent
					type="advert"
					items={this.props.hub.advert}
					brand={this.props.brand}
				/>
			);
		}
		
		return '';
	}
}

function mapStateToProps({ hub, brand }, ownProps){
	return {
		hub,
		brand
	};
}

export default connect(mapStateToProps, { 
	advertGet
})(Advert);