import React from 'react'
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import ReactGA from 'react-ga';
import { I18nextProvider, withTranslation, useTranslation } from 'react-i18next';
import i18n from './helpers/i18n';
import Brand from './components/chrome/brand';
import Loading from './components/chrome/loading';
import Error404 from './components/errors/404';
import LogRocket from 'logrocket';

import Holding from './containers/holding';
import Hub from './containers/hub';
import Slider from './containers/slider';
import Advert from './containers/advert';
import Landing from './containers/landing';
import Video from './containers/video';
import Preview from './containers/preview';

import Articles from './containers/articles';
import Article from './containers/articles/view';

import Subscribe from './containers/subscription/subscribe';
import SubscribeConfirm from './containers/subscription/confirm';
import Unsubscribe from './containers/subscription/unsubscribe';

LogRocket.init(process.env.REACT_APP_LOG_ROCKET_KEY);

const enhancer = compose(
	applyMiddleware(
		promise, 
		reduxThunk,
		LogRocket.reduxMiddleware()
	)
);

const store = createStore(reducers, enhancer);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
	debug: process.env.NODE_ENV === 'production' ? false : true
});

const RouteMapping = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        (window.location.hostname === 'cyclingcampaign.com')
            ? (
            	<Holding {...props} />
            )
            : (
            	<Component {...props} />
            )
    )} />
);

const App = () => {	
	
	 const { ready } = useTranslation(['translation'], { useSuspense: false });
	 	
	 let preview = false;
	 
	 if(["preview.cyclingcampaign.com","preview.staging.cyclingcampaign.com"].includes(window.location.hostname)){
		 preview = true;
	 }
	 
	 if(ready){

		 return (
			<Provider store={store}>
				<BrowserRouter>
					<Brand />
				
					{preview && 
						<Switch>
							<RouteMapping path="/:token" component={Preview} />
							<RouteMapping path="*" component={Error404} />
						</Switch>
					}
					
					{!preview &&
						<Switch>
							<RouteMapping path="/unsubscribe" component={Unsubscribe} />
							<RouteMapping path="/subscribe/:token" component={SubscribeConfirm} />
							<RouteMapping path="/subscribe" component={Subscribe} />
							
							<RouteMapping path="/articles/:url" component={Article} />
							<RouteMapping path="/articles" component={Articles} />
							
							<RouteMapping path="/player/:token" component={Video} />
							<RouteMapping path="/l/:url" component={Landing} />
							<RouteMapping path="/slider" component={Slider} />
							<RouteMapping path="/advert" component={Advert} />
							
							<RouteMapping path="/preview/:token" component={Preview} />
							<RouteMapping path="/" component={Hub} />
							
							<RouteMapping path="*" component={Error404} />
						</Switch>
					}
				</BrowserRouter>
			</Provider>
		);
	}else{
			
		return (
			<Loading />
		);
	}
}

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
    	<App />
    </I18nextProvider>,
	document.getElementById('root')
);

function mapStateToProps({ i18n }, ownProps) {
	return {
		i18n
	};
}

export default connect(
  	connect(mapStateToProps)
)(withTranslation(['translation'])(App));

//export default connect(mapStateToProps)(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();